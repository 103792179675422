import React from 'react';
import { Pie } from 'react-chartjs-2';
import { CircularProgress, Table, TableRow, TableCell } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js/auto';
Chart.register(ChartDataLabels);

const DashboardStockPortfolio = ({ data }) => {
    if (!data) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'inherit' }}>
        <CircularProgress />
        <p>Loading data...</p>
      </div>;
    }

    if (data.length === 0) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'inherit' }}>
            <Table>
            <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center', padding: '30px', fontSize: '42px', opacity: "50%", color: "rgba(0, 0, 0, 0.87)" }}>No data</TableCell>
            </TableRow>
            </Table>
        </div>;
    }

    const groupBySector = (data, priceType) => {
        return data.reduce((acc, stock) => {
            const sector = stock.sector;
            const price = stock[priceType] * stock.units;
            if (!acc[sector]) {
                acc[sector] = 0;
            }
            acc[sector] += price;
            return acc;
        }, {});
    };

    const groupByStock = (data, priceType) => {
        return data.reduce((acc, stock) => {
            const stockName = stock.ticker;
            const price = stock[priceType] * stock.units;
            if (!acc[stockName]) {
                acc[stockName] = 0;
            }
            acc[stockName] += price;
            return acc;
        }, {});
    };

    const boughtPriceDataBySector = groupBySector(data, 'bought_price');
    const currentPriceDataBySector = groupBySector(data, 'current_price');
    const boughtPriceDataByStock = groupByStock(data, 'bought_price');
    const currentPriceDataByStock = groupByStock(data, 'current_price');

    const createChartData = (data) => {
        return {
            labels: Object.keys(data),
            datasets: [
            {
                data: Object.values(data),
                backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
                '#FF9F40',
                '#FF5733',
                '#C70039',
                '#900C3F',
                '#581845',
                '#DAF7A6',
                '#FFC300',
                '#FF5733',
                '#C70039',
                '#900C3F',
                '#581845',
                '#1F618D',
                '#117A65',
                '#D4AC0D',
                '#A93226',
                ],
            },
            ],
        };
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%', // This makes the pie chart a donut chart
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.label || '';
                        return `${label}`;
                    },
                },
                title: {
                    font: {
                        family: 'inherit'
                    }
                },
                bodyFont: {
                    family: 'inherit'
                }
            },
            title: {
                font: {
                    family: 'inherit'
                }
            },
            legend: {
                display: false,
                labels: {
                    font: {
                        family: 'inherit'
                    }
                }
            },
            datalabels: {
                color: '#000',
                font: {
                    family: 'inherit'
                },
                formatter: function(value, context) {
                    const label = context.chart.data.labels[context.dataIndex];
                    return label.includes(' ') ? label.split(' ').join('\n') : label;
            }
        },
        }
    };

    const boughtPriceChartDataBySector = createChartData(boughtPriceDataBySector);
    const currentPriceChartDataBySector = createChartData(currentPriceDataBySector);
    const boughtPriceChartDataByStock = createChartData(boughtPriceDataByStock);
    const currentPriceChartDataByStock = createChartData(currentPriceDataByStock);

    // const CustomLegend = ({ chartData }) => {
    //     return (
    //         <div style={{ }}>
    //             {chartData.labels.map((label, index) => (
    //                 <div key={index} style={{ }}>
    //                     <div style={{ width: '20px', height: '20px', backgroundColor: chartData.datasets[0].backgroundColor[index], marginRight: '5px' }}></div>
    //                     <span style={{ fontFamily: 'Figtree' }}>{label}</span>
    //                 </div>
    //             ))}
    //         </div>
    //     );
    // };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* <h2>Asset Allocation</h2> */}
            </div>
            <Grid container style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                <Grid style={{ position: 'relative', width: '100%', height: '300px' }}>
                    <Pie 
                        data={boughtPriceChartDataBySector} 
                        options={{
                            ...chartOptions,
                            plugins: {
                                ...chartOptions.plugins,
                                title: {
                                    display: true,
                                    text: 'Bought Price vs Sector',
                                    font: {
                                        family: 'inherit'
                                    }
                                }
                            }
                        }} 
                    />
                </Grid>
                <Grid style={{ position: 'relative', width: '100%', height: '300px' }}>
                    <Pie 
                        data={currentPriceChartDataBySector} 
                        options={{
                            ...chartOptions,
                            plugins: {
                                ...chartOptions.plugins,
                                title: {
                                    display: true,
                                    text: 'Current Price vs Sector',
                                    font: {
                                        family: 'inherit'
                                    }
                                }
                            }
                        }} 
                    />
                </Grid>
                <Grid style={{ position: 'relative', width: '100%', height: '300px' }}>
                    <Pie 
                        data={boughtPriceChartDataByStock} 
                        options={{
                            ...chartOptions,
                            plugins: {
                                ...chartOptions.plugins,
                                title: {
                                    display: true,
                                    text: 'Bought Price vs Stock',
                                    font: {
                                        family: 'inherit'
                                    }
                                }
                            }
                        }} 
                    />
                </Grid>
                <Grid style={{ position: 'relative', width: '100%', height: '300px' }}>
                    <Pie 
                        data={currentPriceChartDataByStock} 
                        options={{
                            ...chartOptions,
                            plugins: {
                                ...chartOptions.plugins,
                                title: {
                                    display: true,
                                    text: 'Current Price vs Stock',
                                    font: {
                                        family: 'inherit'
                                    }
                                }
                            }
                        }} 
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardStockPortfolio;