import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';

const DashboardAddStockDialog = ({ open, onClose, formValues, onInputChange, onSubmit }) => {
    const [stockData, setStockData] = useState([]);
    const apiUrl = process.env.REACT_APP_BACKEND_API;
    useEffect(() => {
      const fetchStockData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiUrl}/stocks/available_stocks`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
            });
          const data = await response.json();
          if (Array.isArray(data)) {
            setStockData(data);
          } else {
            console.info(data);
            console.error('Fetched data is not an array:', data);
          }
        } catch (error) {
          console.error('Error fetching stock data:', error);
        }
      };

      fetchStockData();
    }, [apiUrl]);

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filteredSuggestions = stockData.filter(stock =>
        stock.ticker.toLowerCase().includes(searchTerm.toLowerCase()) ||
        stock.name.toLowerCase().includes(searchTerm.toLowerCase())
      ).slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm, stockData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    onInputChange({ target: { name: 'ticker', value: suggestion.ticker } });
    setSearchTerm(suggestion.ticker);
    setSuggestions([]);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add New Stock</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="ticker"
            label="Ticker"
            type="text"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            onBlur={() => setTimeout(() => setSuggestions([]), 100)}
          />
          {suggestions.length > 0 && (
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0, position: 'absolute', backgroundColor: 'white', border: '1px solid #1db954', zIndex: 4, width: '91.7%' }}>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #1db954', backgroundColor: 'white', display: 'flex', justifyContent: 'space-between' }}
                >
                  <span style={{ flex: 1, textAlign: 'left' }}>{suggestion.name}</span>
                  <span style={{ flex: 1, textAlign: 'right' }}>{suggestion.ticker}</span>
                </li>
              ))}
            </ul>
          )}
          <TextField
            margin="dense"
            name="units"
            label="Units"
            type="number"
            fullWidth
            value={formValues.units}
            onChange={onInputChange}
            onFocus={() => setSuggestions([])}
          />
          <TextField
            margin="dense"
            name="bought_price"
            label="Bought Price"
            type="number"
            fullWidth
            value={formValues.bought_price}
            onChange={onInputChange}
            onFocus={() => setSuggestions([])}
          />
          <TextField
            margin="dense"
            name="bought_at"
            label="Bought At"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={formValues.bought_at}
            onChange={onInputChange}
            onFocus={() => setSuggestions([])}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Close</Button>
          <Button onClick={onSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DashboardAddStockDialog;