import { useState, useEffect } from 'react';

const useUserInfo = (apiUrl) => {
  const [userInfo, setUserInfo] = useState({ first_name: '', last_name: '', email: '', is_subscribed: '', is_authenticated: '' });

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiUrl}/user_info`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user info');
        }
        const data = await response.json();
        data.is_authenticated = true;
        setUserInfo(data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setUserInfo((prev) => ({ ...prev, is_authenticated: false }));
      }
    };

    fetchUserInfo();
  }, [apiUrl]);
  console.log('userInfo', userInfo);
  return userInfo;
};

export default useUserInfo;