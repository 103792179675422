import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
// import Footer from './Footer';
import { theme } from './themes/theme';

function Login() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    if (!email || !password) {
      setError('Email and password are required');
      return false;
    }
    setError('');
    return true;
  };

  React.useEffect(() => {
    const checkIfLoggedIn = async () => {
      const apiUrl = process.env.REACT_APP_BACKEND_API;
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      try {
        const response = await fetch(`${apiUrl}/user_info`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };

    checkIfLoggedIn();
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    const apiUrl = process.env.REACT_APP_BACKEND_API;
    const formDetails = {
      email: email,
      password: password,
      remember_me: rememberMe,
    };

    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDetails),
      });

      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.access_token);
        navigate('/dashboard');
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Authentication failed!');
      }
    } catch (error) {
      setLoading(false);
      setError('An error occurred. Please try again later.');
    }
  };

  const handleCreateAccount = () => {
    navigate('/signup');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box 
        // elevation={6} 
        sx={{ 
          padding: 4, 
          marginTop: 8, 
          marginBottom: 4,
          // borderRadius: '8px', 
          // border: '0.5px solid #000',
          border: '1.5px solid rgba(0, 0, 0, 0.2)',
          // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' 
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/logo-green.png`} alt="Logo" style={{ height: '45px', marginRight: '' }} />
            </Box>
            <Typography variant="h3" gutterBottom sx={{ marginBottom: '30px' }}>
              capanu
            </Typography>
            <Typography variant="h5" gutterBottom>
              Sign in
            </Typography>
            <Typography variant="body1" gutterBottom sx={{textAlign: "center"}}>
              Welcome to capanu, please sign in to continue
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    color="primary"
                  />
                }
                label="Remember Me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                borderRadius={0}
                size='large'
                sx={{ mt: 3, mb: 2, textTransform: 'none', borderRadius: '0', marginBottom: '0' }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Login'}
              </Button>
              {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
                <Typography variant="body2" sx={{ mx: 2, color: 'rgba(0, 0, 0, 0.6)' }}>
                  or
                </Typography>
                <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
              </Box>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                size='large'
                sx={{ mt: 1, textTransform: 'none', borderRadius: '0', marginTop: '0' }}
                onClick={handleCreateAccount}
                borderRadius={0}
              >
                Create Account
              </Button>
            </Box>
          </Box>
        </Box>
        <Typography variant="body1" color="textSecondary" sx={{textAlign: 'center'}}>
            &copy; {new Date().getFullYear()} Capanu. All rights reserved.
        </Typography>
      </Container>
      
      {/* <Footer /> */}
    </ThemeProvider>
  );
}

export default Login;
