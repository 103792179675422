import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';
import { parseISO, startOfYear, subMonths, startOfMonth, endOfMonth } from 'date-fns';

const DashboardStockDividend = ({ data }) => {
    const calculateDividendsInRange = (dividendHistory, startDate, endDate) => {
        return dividendHistory
            .filter(record => {
                const recordDate = parseISO(record.date);
                return recordDate >= startDate && recordDate <= endDate;
            })
            .reduce((acc, record) => acc + record.dividend, 0);
    };

    const calculateYTD = (dividendHistory) => {
        const startOfYearDate = startOfYear(new Date());
        const today = new Date();
        return calculateDividendsInRange(dividendHistory, startOfYearDate, today);
    };

    const getLastThreeMonths = () => {
        const today = new Date();
        const lastMonth = subMonths(today, 1);
        const twoMonthsAgo = subMonths(today, 2);
        const threeMonthsAgo = subMonths(today, 3);

        return [
            { start: startOfMonth(lastMonth), end: endOfMonth(lastMonth) },
            { start: startOfMonth(twoMonthsAgo), end: endOfMonth(twoMonthsAgo) },
            { start: startOfMonth(threeMonthsAgo), end: endOfMonth(threeMonthsAgo) }
        ];
    };

    const lastThreeMonths = getLastThreeMonths();

    return (
        data ? (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* <h2>Dividend Payouts</h2> */}
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: "bolder", padding: '8px' }}>Ticker</TableCell>
                            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>Yield</TableCell>
                            {lastThreeMonths.map((month, index) => (
                                <TableCell key={index} style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>
                                    {month.start.toLocaleString('default', { month: 'short' })}
                                </TableCell>
                            ))}
                            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>YTD</TableCell>
                            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>1 Year</TableCell>
                            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((stock) => {
                            const totalDividend = stock.dividend_history.reduce((acc, record) => acc + (record.dividend * stock.units), 0);
                            const monthlyDividends = lastThreeMonths.map(month => 
                                calculateDividendsInRange(stock.dividend_history, month.start, month.end) * stock.units
                            );
                            const ytdDividends = calculateYTD(stock.dividend_history) * stock.units;
                            const lastYearDividends = calculateDividendsInRange(stock.dividend_history, new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()) * stock.units;
                            return (
                                <TableRow key={stock.stock_id}>
                                    <TableCell style={{ fontWeight: "bolder", padding: '8px' }}>{stock.ticker}</TableCell>
                                    <TableCell style={{ padding: '8px', textAlign: "right" }}>{(stock.dividend_yield * 100).toFixed(2)}%</TableCell>
                                    {monthlyDividends.map((dividend, index) => (
                                        <TableCell key={index} style={{ padding: '8px', textAlign: "right" }}>{dividend.toFixed(2)}</TableCell>
                                    ))}
                                    <TableCell style={{ padding: '8px', textAlign: "right" }}>{ytdDividends.toFixed(2)}</TableCell>
                                    <TableCell style={{ padding: '8px', textAlign: "right" }}>{lastYearDividends.toFixed(2)}</TableCell>
                                    <TableCell style={{ padding: '8px', textAlign: "right" }}>{totalDividend.toFixed(2)}</TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell style={{ fontWeight: "bolder", padding: '8px' }}>Total</TableCell>
                            <TableCell style={{ padding: '8px' }}></TableCell>
                            {lastThreeMonths.map((month, index) => (
                                <TableCell key={index} style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>
                                    {data.reduce((acc, stock) => acc + calculateDividendsInRange(stock.dividend_history, month.start, month.end) * stock.units, 0).toFixed(2)}
                                </TableCell>
                            ))}
                            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>
                                {data.reduce((acc, stock) => acc + calculateYTD(stock.dividend_history) * stock.units, 0).toFixed(2)}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>
                                {data.reduce((acc, stock) => acc + calculateDividendsInRange(stock.dividend_history, new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()) * stock.units, 0).toFixed(2)}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>
                                {data.reduce((acc, stock) => acc + stock.dividend_history.reduce((sum, record) => sum + (record.dividend * stock.units), 0), 0).toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <CircularProgress />
        </Box>
    ));
};

export default DashboardStockDividend;