import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import { theme } from './themes/theme';
import CircularProgress from '@mui/material/CircularProgress';

function Signup() {
  const [countries, setCountries] = React.useState([]);

  React.useEffect(() => {
    const fetchCountries = async () => {
      const apiUrl = process.env.REACT_APP_BACKEND_API;
      try {
        const response = await fetch(`${apiUrl}/misc/country_phone_codes`);
        if (response.ok) {
          const data = await response.json();
          setCountries(data);
        } else {
          console.error('Failed to fetch countries');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [fundManager, setFundManager] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    const checkIfLoggedIn = async () => {
      const apiUrl = process.env.REACT_APP_BACKEND_API;
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      try {
      const response = await fetch(`${apiUrl}/user_info`, {
        method: 'GET',
        headers: {
        'Authorization': `Bearer ${token}`,
        },
      });
      console.log('response', response);
      console.log(response);
      if (response.ok) {
        navigate('/dashboard');
      }
      } catch (error) {
      console.error('Error checking login status:', error);
      }
    };

    checkIfLoggedIn();
  }, [navigate]);

  const validateForm = () => {
    if (!email || !password || !firstName || !lastName || !confirmPassword) {
      setError('All fields are required');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    const apiUrl = process.env.REACT_APP_BACKEND_API;
    const payload = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      country_code: countryCode,
      confirm_password: confirmPassword,
      is_fund_manager: fundManager,
    };

    try {
      const response = await fetch(`${apiUrl}/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      setLoading(false);

      if (response.ok) {
        navigate('/signin');
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Registration failed!');
      }
    } catch (error) {
      setLoading(false);
      setError('An error occurred. Please try again later.');
    }
  };

  const handleLoginButton = () => {
    navigate('/signin');
  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box 
        // elevation={3} 
        sx={{ 
          padding: 4, 
          marginTop: 8, 
          marginBottom: 4, 
          borderRadius: '0px',
          // border: '0.5px solid #000',
          border: '1.5px solid rgba(0, 0, 0, 0.2)',
          // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' 
          }}>
          <Box sx={{ display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/logo-green.png`} alt="Logo" style={{ height: '45px', marginRight: '' }} />
            </Box>
            <Typography variant="h3" gutterBottom sx={{ marginBottom: '30px' }}>
              capanu
            </Typography>
            <Typography variant="h5" gutterBottom>Sign up</Typography>
            <Typography variant="body1" gutterBottom sx={{textAlign: "center"}}>
              Welcome to capanu, please create an account to continue
            </Typography>
          </Box>
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="first-name"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              autoFocus
              variant="standard"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="last-name"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              variant="standard"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone-number"
              label="Phone Number"
              name="phoneNumber"
              autoComplete="tel"
              variant="standard"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <TextField
              select
              margin="normal"
              required
              fullWidth
              id="country-code"
              label="Country Code"
              name="countryCode"
              variant="standard"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              slotProps={{
                select: {
                  native: true,
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            >
              {countries.map((country) => (
                <option key={country.code} value={country.phone_code}>
                  {country.name} ({country.phone_code})
                </option>
              ))}
            </TextField>
            <TextField
              select
              margin="normal"
              required
              fullWidth
              id="fund-manager"
              label="Account Type"
              name="fundManager"
              variant="standard"
              value={fundManager}
              onChange={(e) => setFundManager(e.target.value)}
              slotProps={{
                select: {
                  native: true,
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            >
              <option value={false}>Individual</option>
              <option value={true}>Fund Manager</option>
            </TextField>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="new-password"
              type="password"
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirm-password"
              label="Confirm Password"
              name="confirmPassword"
              autoComplete="new-password"
              type="password"
              variant="standard"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              variant="contained"
              type="submit"
              size="large"
              fullWidth
              sx={{ mt: 2, textTransform: 'none', borderRadius: '0' }}
              disabled={loading}
            >
              {/* Create Account */}
              {loading ? <CircularProgress size={24} /> : 'Create Account'}
            </Button>
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
              <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
              <Typography variant="body2" sx={{ mx: 2, color: 'rgba(0, 0, 0, 0.6)' }}>
                or
              </Typography>
              <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
            </Box>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size='large'
              sx={{ mt: 1, textTransform: 'none', marginTop: '0', borderRadius: '0' }}
              onClick={handleLoginButton}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default Signup;
