import { Box } from '@mui/material';

const Section = ({ children }) => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
    >
        {children}
    </Box>
);

export default Section;