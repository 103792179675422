import React from 'react';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import 'chartjs-plugin-crosshair';
import NavBar from './components/Dashboard/NavBar';
import { theme } from './themes/theme';
import useUserInfo from './hooks/useUserInfo';
import { Tabs, Tab, Box, Container, ThemeProvider, CircularProgress } from '@mui/material';
import { useState } from 'react';
import DashboardSummary from './components/Dashboard/DashboardSummary';
import DashboardStockPortfolioTable from './components/Dashboard/DashboardStockTable';
import DashboardSectorPie from './components/Dashboard/DashboardSectorPie';
import DashboardStockPortfolioChart from './components/Dashboard/DashboardStockPortfolioChart';
import DashboardStockChart from './components/Dashboard/DashboardStockChart';
import DashboardStockDividend from './components/Dashboard/DashboardStockDividend';
import PortfolioManager from './components/Dashboard/CreatePortfolio';
import SendInterest from './components/SendInterest';
import Section from './components/Section/Section';
import NullSection from './components/Section/NullSection';
import useFetchData from './hooks/useFetchData';
import useStockManagement from './hooks/useStockManagement';

function ProtectedPage() {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  
  const userInfo = useUserInfo(apiUrl);
  const stockHoldings = useFetchData(`${apiUrl}/stocks/holdings/${localStorage.getItem('selectedPortfolioId')}`);
  const stock_portfolio_data = useFetchData(`${apiUrl}/stocks/total_historic_investment_tracker/${localStorage.getItem('selectedPortfolioId')}`);
  const portfolio_list = useFetchData(`${apiUrl}/portfolio/list`) || [];

  const {
    showPopup,
    formValues,
    timeRange,
    showDeletePopup,
    selectedStocks,
    handleAddStock,
    handleClosePopup,
    handleInputChange,
    handleSubmit,
    handleOpenDeletePopup,
    handleCloseDeletePopup,
    handleConfirmDelete,
    handleStockSelectionChange,
    filteredData,
    filteredChartData,
    setTimeRange
  } = useStockManagement(apiUrl, stockHoldings);

  const sections = [

    { 
      label: 'Stock Portfolio', 
      content: <div>
                  <DashboardStockPortfolioTable
                    data={stockHoldings}
                    filteredData={filteredData}
                    showPopup={showPopup}
                    formValues={formValues}
                    handleAddStock={handleAddStock}
                    handleClosePopup={handleClosePopup}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    showDeletePopup={showDeletePopup}
                    handleOpenDeletePopup={handleOpenDeletePopup}
                    handleCloseDeletePopup={handleCloseDeletePopup}
                    handleConfirmDelete={handleConfirmDelete}
                  />
                </div> 
    },

    {
      label: 'Dividend Payouts',
      content: <div>
                  <DashboardStockDividend data={stockHoldings} />
                </div>
    },
    
    { 
      label: 'Asset Allocation', 
      content: <div>
                  <DashboardSectorPie data={stockHoldings} />
                </div> 
    },

    {
      label: 'Portfolio Performance',
      content: <div>
                  <DashboardStockPortfolioChart
                      data={stock_portfolio_data}
                    />
                </div>
    },

    {
      label: 'Stock Performance',
      content: <div>
                  <DashboardStockChart
                      data={stockHoldings}
                      filteredChartData={filteredChartData}
                      timeRange={timeRange}
                      setTimeRange={setTimeRange}
                      selectedStocks={selectedStocks}
                      handleStockSelectionChange={handleStockSelectionChange}
                    />
                </div>
    },
  ];

  const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

  if (userInfo.is_authenticated === false) {
    window.location.href = "/signin";
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <div style={
        {
          marginBottom: '120px'
        }
      }>
        {userInfo.email !== "" && userInfo.is_subscribed ? (
          stockHoldings && stock_portfolio_data ? (
            <Section>
              <Container>
                <PortfolioManager userInfo={userInfo} portfolio_list={portfolio_list} />
                { portfolio_list.length > 0 ? (
                  <div>
                    { localStorage.getItem('selectedPortfolioId') ? (
                      <div>
                        <DashboardSummary data={stockHoldings}/>
                        <Box display="flex" justifyContent="center" width="100%">
                          <Tabs value={selectedTab} onChange={handleChange} aria-label="section tabs" style={{ textAlign: 'center' }}>
                            {sections.map((section, index) => (
                              <Tab sx={{fontWeight: 'bolder'}} key={index} label={section.label} />
                            ))}
                          </Tabs>
                        </Box>
                        <Box width="100%" marginTop="20px">
                          {sections[selectedTab].content}
                        </Box>
                      </div>
                    ):(
                      <NullSection>
                        Please select a portfolio to start tracking your investments.
                      </NullSection>
                    ) }
                  </div>
                ):(
                  <NullSection>
                    You have not created any portfolio yet. Please create a portfolio to start tracking your investments.
                  </NullSection>
                )}
              </Container>
            </Section>
          ) : (
            <NullSection>
              <CircularProgress />
              <p>Loading data...</p>
            </NullSection>
          )
        ) : (
          <NullSection>
            <SendInterest />
          </NullSection>
        )}
      </div>
    </ThemeProvider>
  );
}

export default ProtectedPage;
