import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from './Signin';
import Signup from './Signup';
import Landing from './Landing';
import Stocks from './Stocks';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Landing />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} />
        <Route path="dashboard" element={<Stocks />} />
        <Route path="stocks" element={<Stocks />} />
      </Routes>
    </Router>
  );
}

export default App;
// No additional code needed at $PLACEHOLDER$