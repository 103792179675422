import React from 'react';
import { Line } from 'react-chartjs-2';
import { Button } from '@mui/material';
import { Table, TableRow, TableCell } from '@mui/material';
import DashboardStockSelection from './DashboardStockSelection';

const ChartSection = ({ filteredChartData, timeRange, setTimeRange }) => {
  if (!filteredChartData || filteredChartData.length === 0) return null;

  const labels = Array.from(new Set(filteredChartData.flatMap(stock => stock.stock_history.map(entry => entry.date)))).sort();
  const colors = [
    'rgba(255,99,132,1)',
    'rgba(75,192,192,1)',
    'rgba(54,162,235,1)',
    'rgba(255,206,86,1)',
    'rgba(75,192,192,1)',
    'rgba(153,102,255,1)',
    'rgba(255,159,64,1)',
    'rgba(255,99,71,1)',
    'rgba(144,238,144,1)',
    'rgba(255,215,0,1)',
    'rgba(0,191,255,1)',
    'rgba(138,43,226,1)',
    'rgba(255,20,147,1)',
    'rgba(0,128,0,1)',
    'rgba(255,140,0,1)'
  ];

  return (
    <div>
      <Line
        data={{
          labels,
          datasets: filteredChartData.map((stock, index) => ({
            label: stock.ticker,
            data: labels.map(date => {
              const entry = stock.stock_history.find(e => e.date === date);
              return entry ? entry.price : null;
            }),
            fill: false,
            borderColor: colors[index % colors.length],
            tension: 0.1,
            pointRadius: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: colors[index % colors.length]
          }))
        }}
        options={{
          responsive: true,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: 'MMM dd, yyyy',
                displayFormats: { day: 'MMM dd, yyyy' }
              },
              ticks: { maxTicksLimit: 6, font: { family: 'inherit' } },
              grid: { display: false }
            },
            y: {
              type: 'logarithmic',
              grid: { display: false },
              ticks: {
                callback: function(value) {
                  return Number(value.toString());
                }
              }
            }
          },
          plugins: {
            tooltip: {
              mode: 'index',
              intersect: false,
              bodyFont: { family: 'inherit' },
              callbacks: {
                label: function (context) {
                  const stock = filteredChartData[context.datasetIndex];
                  const entry = stock.stock_history.find(e => {
                    const date = new Date(e.date);
                    const paddedDay = date.getDate().toString().padStart(2, '0');
                    const formattedDateWithPadding = `${date.toLocaleString('en-US', { month: 'short' })} ${paddedDay}, ${date.getFullYear()}`;
                    return formattedDateWithPadding === context.label;
                  });
                  return `${stock.ticker}: ${entry ? entry.price.toFixed(2) : 'N/A'}`;
                }
              }
            },
            crosshair: {
              sync: { enabled: false },
              line: { color: 'rgba(0, 0, 0, 0.8)', width: 1 },
              zoom: { enabled: false }
            },
            legend: {
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 20,
                boxWidth: 10,
                boxHeight: 10,
                font: { family: 'inherit' },
                generateLabels: (chart) => {
                  const data = chart.data;
                  return data.datasets.map((dataset, i) => ({
                    text: dataset.label,
                    fillStyle: colors[i % colors.length],
                    hidden: !chart.isDatasetVisible(i),
                    lineCap: dataset.borderCapStyle,
                    lineDash: dataset.borderDash,
                    lineDashOffset: dataset.borderDashOffset,
                    lineJoin: dataset.borderJoinStyle,
                    strokeStyle: dataset.borderColor,
                    pointStyle: 'circle',
                    datasetIndex: i
                  }));
                }
              },
              align: 'start',
            },
            datalabels: {
              display: false,
            }
          },
          interaction: { mode: 'index', intersect: false },
          hover: { mode: 'index', intersect: false }
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'left', marginTop: '20px' }}>
        {['1W', '1M', '3M', 'YTD', '1Y', 'ALL'].map(range => (
          <Button
            key={range}
            color="primary"
            onClick={() => setTimeRange(range)}
            style={{ margin: '0 0px', borderRadius: '0px' }}
            size='small'
            variant={timeRange === range ? 'outlined' : 'text'}
          >
            {range}
          </Button>
        ))}
      </div>
    </div>
  );
};

const DashboardStockChart = ({
  data,
  filteredChartData,
  timeRange,
  setTimeRange,
  selectedStocks,
  handleStockSelectionChange
}) => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* <h2>Stocks Performance</h2> */}
            </div>
        <div>
      {data && (
        <DashboardStockSelection data={data} selectedStocks={selectedStocks} handleStockSelectionChange={handleStockSelectionChange} />
      )}
      {data && data.length === 0 && (
        <Table>
          <TableRow>
            <TableCell colSpan={8} style={{ textAlign: 'center', padding: '30px', fontSize: '42px', opacity: "50%", color: "rgba(0, 0, 0, 0.87)" }}>No data</TableCell>
          </TableRow>
        </Table>
      )}
      <ChartSection filteredChartData={filteredChartData} timeRange={timeRange} setTimeRange={setTimeRange} />
    </div>
  </div>
);

export default DashboardStockChart;
