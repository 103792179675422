import { createTheme } from "@mui/material";

export const theme = createTheme({
  shadows: Array(25).fill("none"),
  palette: {
    primary: {
      main: '#000',
      contrastText: '#fff',
    },
  },
  components: {
    MuiGrid2: {
      defaultProps: {
        disableEqualOverflow: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Figtree', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 14,
    h1: {
      fontFamily: "'Figtree', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '2rem',
    },
    h2: {
      fontFamily: "'Figtree', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '1.75rem',
    },
  },
});
