import React from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';

const PortfolioManager = ({ userInfo, portfolio_list }) => {
    const apiUrl = process.env.REACT_APP_BACKEND_API;
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [portfolioName, setPortfolioName] = useState('');
    const handlePortfolioChange = (event) => {
        const selectedPortfolioId = event ? event.target.value : '';
        localStorage.setItem('selectedPortfolioId', selectedPortfolioId);
        window.location.reload();
        };
    const handleClickOpen = () => {
        setOpen(true);
        };
    
    const handleClose = () => {
        setOpen(false);
        };

    const handlePortfolioNameChange = (event) => {
        setPortfolioName(event.target.value);
        };

    const handleFormSubmit = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${apiUrl}/portfolio/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: portfolioName })
        });
        if (response.ok) {
            setSuccessMessage('Portfolio created successfully.');
            setTimeout(() => {
                setSuccessMessage('');
                window.location.reload();
            }, 3000);
        } else {
            setError('An error occurred while creating the portfolio.');
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while creating the portfolio.');
    }
    handleClose();
    };

    return (
        <div>
        <Grid container spacing={2}
            style={{
            display: 'flex',
            marginBottom: '20px',
            justifyContent: 'space-between'
            }}>
            <Grid item xs={12} md={6} style={{ textAlign: 'left' }} >
            <h1>Welcome {userInfo.first_name}</h1>
            </Grid>
            <Grid item xs={12} md={6} sx={{ paddingTop: '13px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                fullWidth
                style={{ textTransform: 'none', borderRadius: 0, marginRight: '10px' }}
            >
                Create Portfolio
            </Button>
            <FormControl fullWidth
            size='small'
            style={{
                minWidth: '280px',
            }}>
                <InputLabel id="portfolio-select-label">Select Portfolio</InputLabel>
                <Select
                labelId="portfolio-select-label"
                id="portfolio-select"
                value={localStorage.getItem('selectedPortfolioId') || ''}
                label="Select Portfolio"
                onChange={handlePortfolioChange}
                >
                {portfolio_list.map((portfolio) => (
                    <MenuItem key={portfolio.id} value={portfolio.id}>
                    {portfolio.name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create Portfolio</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="portfolioName"
                    label="Portfolio Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={portfolioName}
                    onChange={handlePortfolioNameChange}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleFormSubmit} color="primary">
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
            </Grid>
            
        </Grid>
        <div>
        {successMessage && (
            <p style={{ color: 'green' }}>{successMessage}</p>
        )}
        {error && (
            <p style={{ color: 'red' }}>{error}</p>
        )}
        </div>
        </div>
    );
};

export default PortfolioManager;