import { useState } from 'react';
import { subDays, subMonths, startOfYear } from 'date-fns';

const useStockManagement = (apiUrl, data) => {
  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    ticker: '',
    units: '',
    bought_price: '',
    bought_at: '',
    portfolio_id: localStorage.getItem('selectedPortfolioId'),
  });
  const [timeRange, setTimeRange] = useState('ALL');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteStockId, setDeleteStockId] = useState(null);
  const [selectedStocks, setSelectedStocks] = useState([]);

  const handleAddStock = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);
  const handleInputChange = (e) => setFormValues({ ...formValues, [e.target.name]: e.target.value });

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiUrl}/stocks/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formValues)
      });
      if (!response.ok) throw new Error('Failed to add stock');
      window.location.reload();
    } catch (error) {
      console.error('Error adding stock:', error);
    }
    handleClosePopup();
  };

  const handleDeleteStock = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiUrl}/stocks/delete/${localStorage.getItem('selectedPortfolioId')}/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to delete data');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleOpenDeletePopup = (id) => {
    setDeleteStockId(id);
    setShowDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
    setDeleteStockId(null);
  };

  const handleConfirmDelete = async () => {
    if (deleteStockId) {
      await handleDeleteStock(deleteStockId);
      handleCloseDeletePopup();
    }
  };

  const handleStockSelectionChange = (event) => setSelectedStocks(event.target.value);

  const filterDataByTimeRange = (data, range) => {
    const now = new Date();
    let startDate;

    switch (range) {
      case '1W':
        startDate = subDays(now, 7);
        break;
      case '1M':
        startDate = subMonths(now, 1);
        break;
      case '3M':
        startDate = subMonths(now, 3);
        break;
      case 'YTD':
        startDate = startOfYear(now);
        break;
      case '1Y':
        startDate = subMonths(now, 12);
        break;
      case 'ALL':
      default:
        return data;
    }

    return data.map(stock => ({
      ...stock,
      stock_history: stock.stock_history.filter(entry => new Date(entry.date) >= startDate)
    }));
  };

  const filteredData = filterDataByTimeRange(data || [], timeRange);
  const filteredChartData = selectedStocks.length > 0
    ? filteredData.filter(stock => selectedStocks.includes(stock.ticker))
    : filteredData;

  return {
    showPopup,
    formValues,
    timeRange,
    showDeletePopup,
    deleteStockId,
    selectedStocks,
    handleAddStock,
    handleClosePopup,
    handleInputChange,
    handleSubmit,
    handleOpenDeletePopup,
    handleCloseDeletePopup,
    handleConfirmDelete,
    handleStockSelectionChange,
    filteredData,
    filteredChartData,
    setTimeRange
  };
};

export default useStockManagement;