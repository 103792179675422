const NullSection = ({ children }) => (
    <div
        marginTop="300px"
    >
    <p style={{textAlign: 'center', marginTop: "160px"}}>
        {children}
    </p>
    </div>
);

export default NullSection;