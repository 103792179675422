import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
// import Dropdown from './StockRowDropdown';
import { Button, CircularProgress } from '@mui/material';
import DashboardAddStockDialog from './DashboardAddStockDialog';
import DashboardDeleteStockDialog from './DashboardDeleteStockDialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DashboardStockTable = ({ data, onEdit, onDelete, handleAddStock }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: "bolder", padding: '8px' }}>Ticker</TableCell>
          <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>Units</TableCell>
          {/* <TableCell style={{ fontWeight: "bolder", padding: '8px' }}>Dividend Yield</TableCell> */}
          {/* <TableCell style={{ fontWeight: "bolder", padding: '8px' }}>Bought At</TableCell> */}
          <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>Bought Price</TableCell>
          <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>Invested Value</TableCell>
          <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>Current Price</TableCell>
          <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>Current Value</TableCell>
          <TableCell style={{ fontWeight: "bolder", textAlign: "right", padding: '8px' }}>
            <Button variant="contained" color="primary" onClick={handleAddStock} sx={{ textTransform: 'none', borderRadius: 0}}>Add New</Button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={8} style={{ textAlign: 'center', padding: '30px', fontSize: '42px', opacity: "50%" }}>No data</TableCell>
          </TableRow>
        ) : (
          data.map((row) => (
            <TableRow key={row.portfolio_id}>
              <TableCell style={{ fontWeight: "bolder", padding: '8px' }}>{row.ticker}</TableCell>
              <TableCell style={{ padding: '8px', textAlign: "right" }}>{row.units}</TableCell>
              {/* <TableCell style={{ padding: '8px' }}>
                {`${(row.dividend_yield * 100).toFixed(2)}%`}
              </TableCell> */}
              {/* <TableCell style={{ padding: '8px' }}>{row.bought_at}</TableCell> */}
              <TableCell style={{ padding: '8px', textAlign: "right" }}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row.bought_price)}</TableCell>
              <TableCell style={{ padding: '8px', textAlign: "right" }}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row.units * row.bought_price)}</TableCell>
              <TableCell style={{ padding: '8px', textAlign: "right" }}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row.current_price)}</TableCell>
              <TableCell style={{ padding: '8px', textAlign: "right" }}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row.units * row.current_price)}</TableCell>
              <TableCell style={{ padding: '8px', textAlign: "right" }}>
                <Dropdown
                  id={row.portfolio_id}
                  onEdit={() => onEdit(row.portfolio_id)}
                  onDelete={() => onDelete(row.portfolio_id)}
                />
              </TableCell>
            </TableRow>
          ))
        )}
        {data.length > 0 && (
          <TableRow>
            <TableCell colSpan={3} style={{ fontWeight: "bolder", textAlign: "left", padding: '8px' }}>Total</TableCell>
            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>
              {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.reduce((acc, row) => acc + (row.units * row.bought_price), 0))}
            </TableCell>
            <TableCell style={{ padding: '8px' }}></TableCell>
            <TableCell style={{ fontWeight: "bolder", padding: '8px', textAlign: "right" }}>
              {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.reduce((acc, row) => acc + (row.units * row.current_price), 0))}
            </TableCell>
            <TableCell style={{ padding: '8px' }}></TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

const Dropdown = ({ id, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ minWidth: 'auto', padding: 0 }}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        sx={{ marginTop: '25px', marginLeft: '-10px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => { onEdit(id); handleClose(); }}>Edit</MenuItem> */}
        <MenuItem onClick={() => { onDelete(id); handleClose(); }}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

const DashboardStockPortfolioTable = ({
  data,
  filteredData,
  showPopup,
  formValues,
  handleAddStock,
  handleClosePopup,
  handleInputChange,
  handleSubmit,
  showDeletePopup,
  handleOpenDeletePopup,
  handleCloseDeletePopup,
  handleConfirmDelete
}) => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {/* <h2>Stocks Portfolio</h2> */}
      {/* <Button variant="contained" color="primary" onClick={handleAddStock} sx={{ textTransform: 'none', borderRadius: 0}}>Add New</Button> */}
    </div>
    {data ? (
      <DashboardStockTable data={filteredData} onEdit={() => {}} onDelete={handleOpenDeletePopup} handleAddStock={handleAddStock} />
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
        <p>Loading data...</p>
      </div>
    )}
    {showPopup && (
      <DashboardAddStockDialog open={showPopup} onClose={handleClosePopup} formValues={formValues} onInputChange={handleInputChange} onSubmit={handleSubmit} />
    )}
    {showDeletePopup && (
      <DashboardDeleteStockDialog open={showDeletePopup} onClose={handleCloseDeletePopup} onConfirm={handleConfirmDelete} />
    )}
  </div>
);

export default DashboardStockPortfolioTable;