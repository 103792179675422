import { React, useState } from 'react';
import { Button } from '@mui/material';
import { Lock } from '@mui/icons-material';

const SendInterest = () => {
    const apiUrl = process.env.REACT_APP_BACKEND_API;
    const [successInterestMessage, setInterestSuccessMessage] = useState('');
    const [interestError, setInterestError] = useState('');
    const handleSubscribeClick = async () => {
        const token = localStorage.getItem('token')
        try {
          const response = await fetch(`${apiUrl}/send_interest`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (response.ok) {
            setInterestSuccessMessage('Subscription request sent successfully. Someone from our team will reach out to you soon.'); 
            setTimeout(() => {
                setInterestSuccessMessage('');
            }, 30000);
          } else {
            setInterestError('An error occurred while sending the subscription request.');
            setTimeout(() => {
                setInterestError('');
            }, 30000);
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred while sending the subscription request.');
        }
      };
    
    return (
        <div>
            <Lock style={{ fontSize: 80, marginBottom: 0, opacity: '60%' }} />
            <p>Click the button below to send a request and someone from our team will reach out to you to set up your subscription.</p>
            <Button
                variant="contained"
                color="primary"
                size='large'
                onClick={handleSubscribeClick}
                style={{ textTransform: 'none', marginBottom: 20, borderRadius: 0 }}
            >
                I'm ready to subscribe
            </Button>
            {successInterestMessage && (
                <p style={{ color: 'green' }}>{successInterestMessage}</p>
            )}
            {interestError && (
                <p style={{ color: 'red' }}>{interestError}</p>
            )}
        </div>
    );
};

export default SendInterest;