import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './themes/theme';
import NavBar from './components/Landing/NavBar';
import { Container, Typography, Button, Box } from '@mui/material';
import Footer from './Footer';
import Grid from '@mui/material/Grid2';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import EmergencyIcon from '@mui/icons-material/Emergency';


export default function Landing() {
  return (
    <ThemeProvider theme={theme} style={{}}>
      <NavBar />
      <div>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '80px' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              padding: '20px',
              borderRadius: '0',
              backgroundColor: 'white',
              width: '100%',
              maxWidth: '600px',
              wordSpacing: '0.1em',
              justifyContent: 'center'
            }}>
              <Typography variant="h3" gutterBottom>
                Ultimate Portfolio & Funds Management Solution
              </Typography>
              <Typography variant="body1" gutterBottom sx={{opacity: '70%'}}>
                Designed to help investors, fund managers, and financial advisors efficiently manage investment portfolios. Provides tools for tracking investments, analyzing performance, and making informed decisions to optimize returns.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button variant="contained" color="primary" href="/signup" sx={{ marginRight: '10px', textTransform: 'none', borderRadius: 0 }}>Create Account</Button>
                <Button variant="outlined" color="primary" href="/signin" sx={{ textTransform: 'none', borderRadius: 0 }}>Login</Button>
              </Box>
            </Box>
          </Box>
          <Box id="about" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', marginTop: '60px' }}>
            <Box sx={{ flex: 1, padding: '0px 20px 0px' }}>
              <Box sx={{ paddingTop: '70px', paddingBottom: '70px' }}>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold', opacity: '50%', margin: '20px 0 20px' }}>
                  ABOUT US
                </Typography>
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  Revolutionize the Way You Manage Investments
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'left', marginBottom: '20px', opacity: '70%' }}>
                  Investment management shouldn’t be complicated. Our platform simplifies the process, allowing you to focus on what really matters—making smarter investment decisions. Whether you're managing a personal portfolio or overseeing funds for multiple clients, our platform brings all the tools you need into one cohesive and easy-to-use interface.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ flex: 1, padding: '0px 20px 0px', marginBottom: '70px' }}>
              <Grid container spacing={4} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center' }}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ width: '180px', 
                    height: '200px', 
                    padding: '20px', 
                    borderRadius: '0', 
                    // border: '0.5px solid #000',
                    // border: '1.5px solid rgba(0, 0, 0, 0.2)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center' 
                    }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Real-Time Data Access
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ opacity: '70%' }}>
                      Stay ahead of trends with live portfolio updates and alerts that keep you informed 24/7.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ width: '180px', 
                    height: '200px', 
                    padding: '20px', 
                    borderRadius: '0', 
                    // border: '0.5px solid #000',
                    // border: '1.5px solid rgba(0, 0, 0, 0.2)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center' 
                    }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Automated Workflows
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ opacity: '70%' }}>
                      Automate repetitive tasks like portfolio rebalancing, reporting, and performance tracking.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ width: '180px', 
                    height: '200px', 
                    padding: '20px', 
                    borderRadius: '0', 
                    // border: '0.5px solid #000',
                    // border: '1.5px solid rgba(0, 0, 0, 0.2)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center' }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Optimal Dashboards
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ opacity: '70%' }}>
                      Optimal workspace to prioritize the data that matters most to you whether that’s asset allocation, or investment returns.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ width: '180px', 
                    height: '200px', 
                    padding: '20px', 
                    borderRadius: '0', 
                    // border: '0.5px solid #000',
                    // border: '1.5px solid rgba(0, 0, 0, 0.2)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center' }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Comprehensive Analytics
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ opacity: '70%' }}>
                      Our advanced analytics tools help you make informed decisions by analyzing market conditions, portfolio health, and more.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box id='features' sx={{padding: '0px 20px 0px' }}>
            <Box sx={{ marginTop: '60px' }}>
              <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold', opacity: '50%', margin: '20px 0 20px' }}>
                KEY FEATURES
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                Advanced Features Designed for Professionals
              </Typography>
              <Box sx={{ display: 'flex', overflowX: 'auto', padding: '20px 0' }}>
                {[
                  {
                    icon: <QueryStatsIcon sx={{ fontSize: 60, color: 'primary.main', display: 'block', margin: '0 auto' }} />,
                    title: 'Portfolio Tracking and Monitoring',
                    description: 'Track your portfolio in real-time across multiple asset classes. View detailed breakdowns of performance metrics, asset allocation, and exposure, allowing you to respond swiftly to market shifts.',
                    whyItMatters: 'Stay proactive in managing portfolio performance and optimizing returns, minimizing losses from market downturns.'
                  },
                  {
                    icon: <TroubleshootIcon sx={{ fontSize: 60, color: 'primary.main', display: 'block', margin: '0 auto' }} />,
                    title: 'Investment Analytics and Reporting',
                    description: 'Generate powerful reports that provide clear, actionable insights. Break down performance by asset class, region, and risk factors to get a holistic view of your portfolio.',
                    whyItMatters: 'Communicate effectively with clients or stakeholders by sharing data-backed reports, enhancing transparency and trust.'
                  },
                  {
                    icon: <FolderSpecialIcon sx={{ fontSize: 60, color: 'primary.main', display: 'block', margin: '0 auto' }} />,
                    title: 'Multi-Asset Class Support',
                    description: 'Support for a wide variety of assets: stocks, bonds, mutual funds, ETFs, alternative assets, and more. Seamlessly manage diverse portfolios with advanced tools for each asset class.',
                    whyItMatters: 'Consolidate your entire investment portfolio, making it easier to manage and analyze performance across all asset types.'
                  },
                  {
                    icon: <EmergencyIcon sx={{ fontSize: 60, color: 'primary.main', display: 'block', margin: '0 auto' }} />,
                    title: 'Risk Management Tools',
                    description: 'Access built-in tools for stress testing and scenario analysis, helping you assess potential risks and their impact on your portfolio. Use these insights to implement risk mitigation strategies.',
                    whyItMatters: 'Mitigate risks before they affect your returns. Stay ahead of market volatility with continuous risk evaluation.'
                  }
                ].map((feature, index) => (
                  <Box key={index} sx={{ minWidth: '300px', 
                  marginRight: '20px', 
                  padding: '40px 20px 40px', 
                  borderRadius: '0', 
                  // border: '0.5px solid #000',
                  // border: '1.5px solid rgba(0, 0, 0, 0.2)',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                  backgroundColor: 'white' }}>
                    <div sx={{ margin: '20px' }}>{feature.icon}</div> 
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: '30px' }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ opacity: '70%' }}>
                      {feature.description}
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold', marginTop: '10px' }}>
                      Why it matters:
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ opacity: '70%' }}>
                      {feature.whyItMatters}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box sx={{padding: '0px 20px 0px' }}>
            <Box id="how-it-works" sx={{ marginTop: '60px' }}>
              <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold', opacity: '50%', margin: '20px 0 20px' }}>
                HOW IT WORKS
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                How Our Platform Works for You
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '40px', position: 'relative' }}>
                
                {[
                  {
                    title: 'Sign Up and Connect Your Accounts',
                    description: 'Create an account in minutes and securely link your investment accounts. We support major brokerages and financial institutions, ensuring seamless integration with your existing investments.'
                  },
                  {
                    title: 'Set Your Goals and Preferences',
                    description: 'Customize the platform to align with your specific investment goals, whether that’s aggressive growth, steady income, or capital preservation. Choose which metrics matter most to you, and configure your dashboard accordingly.'
                  },
                  {
                    title: 'Track and Optimize Your Investments',
                    description: 'Use real-time data to monitor your portfolio’s performance and rebalance when necessary. Our algorithmic recommendations help you stay optimized for maximum returns with minimal risk.'
                  },
                  {
                    title: 'Generate Reports and Collaborate',
                    description: 'Create custom reports for clients, stakeholders, or personal use. Export reports in various formats and collaborate with your team or clients directly through our platform’s secure communication tools.'
                  }
                ].map((step, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '40px', width: '100%', maxWidth: '800px' }}>
                    <Box sx={{ position: 'absolute', left: '', marginLeft: '14px', marginBottom: '35px', top: 0, bottom: 0, width: '2px', backgroundColor: 'primary.main' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', marginRight: '20px', position: 'relative' }}>
                      <Box sx={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: 'primary.main', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1 }}>
                        {index + 1}
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {step.title}
                      </Typography>
                      <Typography variant="body2" gutterBottom sx={{ opacity: '70%' }}>
                        {step.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{padding: '0px 20px 0px' }}>
            <Box id="reviews" sx={{ marginTop: '60px' }}>
              <Box sx={{ marginTop: '60px' }}>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold', opacity: '50%', margin: '20px 0 20px' }}>
                  TESTIMONIALS
                </Typography>
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  What Our Clients Are Saying
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '40px' }}>
                  {[
                    {
                      text: "I used to spend hours manually tracking my portfolio. Now, everything is automated, and the data-driven insights have helped me grow my clients' wealth significantly.",
                      author: "John D., Fund Manager, New York City"
                    },
                    {
                      text: "The multi-asset support is a game-changer. I can monitor all my investments in one place and get instant alerts when I need to make adjustments.",
                      author: "Lisa W., Wealth Management Advisor, London"
                    },
                    {
                      text: "The ability to share live performance reports with my clients has improved our relationship tenfold. The platform is intuitive, powerful, and helps us collaborate easily.",
                      author: "Steve M., Independent Financial Advisor, Sydney"
                    }
                  ].map((testimonial, index) => (
                    <Box key={index} sx={{ marginBottom: '40px', 
                    padding: '20px', 
                    borderRadius: '0', 
                    border: '0.5px solid #000',
                    // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                    backgroundColor: 'white', 
                    maxWidth: '800px', textAlign: 'left' }}>
                      <Typography variant="body1" gutterBottom sx={{ fontStyle: 'italic', opacity: '70%' }}>
                        "{testimonial.text}"
                      </Typography>
                      <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold', marginTop: '10px' }}>
                        — {testimonial.author}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{padding: '0px 20px 0px' }}>
            <Box id="pricing" sx={{ marginTop: '60px' }}>
              <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold', opacity: '50%', margin: '20px 0 20px' }}>
                PRICING
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                Choose a Plan That Fits Your Needs
              </Typography>
              <Grid container spacing={4} sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                {[
                  {
                    title: 'Monthly Plan',
                    description: 'Ideal for individual investors looking for essential tools to track and manage their portfolios.',
                    features: [
                      'Real-time portfolio tracking',
                      'Access to standard analytics',
                      'Advanced analytics',
                      'Risk management tools'
                    ],
                    price: '$49/month',
                    buttonText: 'Get Started'
                  },
                  {
                    title: 'Yearly Plan',
                    description: 'Ideal for individual investors looking for essential tools to track and manage their portfolios.',
                    features: [
                      'Real-time portfolio tracking',
                      'Access to standard analytics',
                      'Advanced analytics',
                      'Risk management tools'
                    ],
                    price: '$588/year',
                    buttonText: 'Get Started'
                  },
                  {
                    title: 'Enterprise Plan',
                    description: 'Designed for teams, firms, or financial institutions managing large portfolios and multiple clients.',
                    features: [
                      'Multi-client management',
                      'Team collaboration tools',
                      'Advanced security protocols',
                      'Dedicated custom reports'
                    ],
                    price: 'Custom pricing',
                    buttonText: 'Contact Us'
                  }
                ].map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box sx={{ padding: '20px', 
                      borderRadius: '0', 
                      border: '0.5px solid #000',
                      // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                      backgroundColor: 'white', 
                      textAlign: 'center', 
                      maxWidth: '250px', 
                      height: '380px' }}>
                      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {plan.title}
                      </Typography>
                      <Typography variant="body2" gutterBottom sx={{ opacity: '70%', marginBottom: '20px' }}>
                        {plan.description}
                      </Typography>
                      <ul style={{ listStyleType: '', padding: '20px', textAlign: 'left' }}>
                        {plan.features.map((feature, idx) => (
                          <li key={idx} style={{ marginBottom: '0px' }}>
                            <Typography variant="body2" sx={{ opacity: '70%' }}>
                              {feature}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100px' }}>
                      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: '0px' }}>
                        {plan.price}
                      </Typography>
                      <Button variant="contained" color="primary" sx={{ marginTop: '20px', textTransform: 'none', borderRadius: 0 }} href='/signup'>
                        {plan.buttonText}
                      </Button>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box> */}
          <Box sx={{padding: '0px 20px 0px' }}>
            <Box id="closing-pitch" sx={{ marginTop: '100px', marginBottom: '100px', textAlign: 'center', padding: '40px', backgroundColor: 'primary', borderRadius: '16px' }}>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                Join Hundreds of Fund Managers and Investors Using Our Platform
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ opacity: '70%', marginBottom: '20px' }}>
                Whether you’re managing your own investments or overseeing portfolios for clients, our software provides everything you need to stay on top of your finances. Optimize performance, reduce risk, and collaborate with ease—all from one powerful platform.
              </Typography>
              <Button variant="contained" color="primary" href="/signup" sx={{ textTransform: 'none', borderRadius: 0 }}>
                Get Started Now
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
      <Container>  
        <Footer />
      </Container>
      
    </ThemeProvider>
  );
}
